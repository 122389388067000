<template>
<div>
    <a-card title="增加出库记录">
        <template #extra>
            <a-button @click="$router.go(-1)">返回</a-button>
        </template>
        <div class="aib-item">
            <div class="aib-item-li">
                <span>出库方式：</span>
                <a-select v-model:value="eobState.form.class" style="width: 200px" placeholder="选择入库方式" @change="changeMethod">
                    <a-select-option :value="1">销售出库</a-select-option>
                    <a-select-option :value="2">调用出库</a-select-option>
                    <a-select-option :value="3">调拔出库</a-select-option>
                </a-select>
            </div>
            <div class="aib-item-li">
                <span>单据日期：</span>
                <a-date-picker v-model:value="eobState.form.document_date" />
            </div>
        </div>

        <a-table class="tb-overflow"
                 :pagination="false"
                 rowKey="class_id"
                 :data-source="eobState.form.data"
                 :columns="eobState.columns"
                 :scroll="{ x: 1500 }"
        >
            <template #action="{record,index}">
                <i class="ri-xl ri-top pt ri-add-line" @click="addOrDelProduct('add',index)"></i>
                <i class="ri-xl ri-top pt ri-subtract-line"
                   @click="addOrDelProduct('del',index)"
                   v-if="eobState.form.data.length >= 2"></i>
            </template>
            <template #name="{record,index}">
                <div v-if="record.class_id">
                    <span>{{record.productInfo?.name}}</span>
                    <span>{{record.productInfo?.kd_spec?.model}}</span>
                </div>
                <div v-else>
                    <com-check-product @success="getSelectProduct"
                                       :index="index"
                                       :checked-data="eobState.form.data">
                    </com-check-product>
                </div>
            </template>
            <template #farm="{record,index}">
                <div v-if="record.class_id">
                    <span>{{record.productInfo?.name}}</span>
                </div>
                <div v-else>
                    <com-check-farm-data @success="getSelectProduct"
                                         :index="index"
                                         :checked-data="eobState.form.data">
                    </com-check-farm-data>
                </div>
            </template>
            <template #amount="{record}">
                <div v-if="record.class_id">
                    <a-tooltip>
                        <template #title>
                            <div>出库仓库：{{record.productInfo?.depot?.name}}</div>
                            <div>现存库存：{{record.productInfo?.stock}} {{record.productInfo?.unit?.name}}</div>
                        </template>
                        <a-input v-model:value="record.amount"
                                 :addon-after="record.productInfo?.unit?.name"
                                 style="width: 120px">
                        </a-input>
                    </a-tooltip>
                </div>
            </template>
            <template #unit_price="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.unit_price" addon-after="元" style="width: 120px"></a-input>
                </div>
            </template>
            <template #total="{record}">
                <div v-if="record.class_id">
                    ￥{{record.amount * record.unit_price}}
                </div>
            </template>

            <template #guest="{record}">
                <div v-if="record.class_id">
                    <com-select-customer @success="e=>record.guest_id = e"></com-select-customer>
                </div>
            </template>
            <template #seller="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.seller" style="width: 120px"></a-input>
                </div>
            </template>
            <template #caller="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.caller" style="width: 120px"></a-input>
                </div>
            </template>
            <template #loss="{record}">
                <div v-if="record.class_id">
                    <a-select v-model:value="record.loss" style="width: 120px" placeholder="物品状态">
                        <a-select-option :value="1">完好无损</a-select-option>
                        <a-select-option :value="2">轻微损耗</a-select-option>
                        <a-select-option :value="3">中度损耗</a-select-option>
                        <a-select-option :value="4">严重损耗</a-select-option>
                    </a-select>
                </div>
            </template>
            <template #call_type="{record}">
                <div v-if="record.class_id">
                    <a-select v-model:value="record.call_type" style="width: 120px" placeholder="调用类型">
                        <a-select-option :value="1">无需归还</a-select-option>
                        <a-select-option :value="2">需归还</a-select-option>
                        <a-select-option :value="3">已归还</a-select-option>
                    </a-select>
                </div>
            </template>
            <template #receive_depot_id="{record}">
                <div v-if="record.class_id">
                    <a-select v-model:value="record.receive_depot_id" style="width: 130px" placeholder="选择仓库">
                        <a-select-option :value="item.id" v-for="(item,index) in eobState.entrepot" :key="index">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
            </template>
            <template #remark="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.remark" style="width: 120px"></a-input>
                </div>
            </template>
            <template #desc="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.desc" style="width: 120px"></a-input>
                </div>
            </template>
            <template #voucher="{record}">
                <div v-if="record.class_id">
                    <span v-if="record.voucher">已上传</span>
                    <com-upload-btn :custom-upload="true" btn-type="link" @uploadChange="e=>record.voucher = e">
                        <a-button type="link">上传凭证</a-button>
                    </com-upload-btn>
                </div>
            </template>
        </a-table>

        <div class="aib-item mt12">
            <a-space>
                <a-button type="primary" @click="saveOutBound">保存</a-button>
            </a-space>
        </div>
    </a-card>
</div>
</template>

<script>
import { useEditOutBound } from '@/models/addons/storage'
import comUploadBtn from '@/components/public/com-upload-btn'
import comSelectCustomer from './com-select-customer.vue'
import comCheckProduct from './com-check-product.vue'
import comCheckFarmData from './com-check-farm-data.vue'
export default {
    components:{
        comUploadBtn,
        comSelectCustomer,
        comCheckProduct,
        comCheckFarmData
    },
    setup(){
        let { eobState,deleteProduct,saveOutBound,addOrDelProduct,getSelectProduct,changeMethod} = useEditOutBound()

        return { eobState,deleteProduct,saveOutBound,addOrDelProduct,getSelectProduct,changeMethod }
    }
}
</script>

<style scoped lang="scss">
.aib-item{
    display:flex;
    margin-bottom: 24px;

    &-li{
        width: 400px;
    }
}
</style>
